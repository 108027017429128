import "./navbar.scss";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
// import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
// import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" name="" id="" placeholder="search..." />
          <SearchTwoToneIcon />
        </div>
        <div className="items">
          <div className="item">
            <AccountBoxTwoToneIcon className="icon" />
            Welcome, {currentUser.user.displayName}
          </div>

          {/* <div className="item">
            <NotificationsActiveTwoToneIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
