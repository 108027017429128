import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  serverTimestamp,
  setDoc,
  getDocs,
} from "firebase/firestore";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "../../firebase-config";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DriveFolderUploadTwoToneIcon from "@mui/icons-material/DriveFolderUploadTwoTone";
import "./new.scss";
import { useNavigate } from "react-router-dom";

const New = ({ input, title, cat }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vesselData, setVesselData] = useState([]);

  const navigate = useNavigate();
  // const [error, setError] = useState(false);

  const getData = async () => {
    // alert('hi')
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, "vessels"));
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      // console.log(list);
      setVesselData(list);
      setIsLoading(false);
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, [cat]);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  const handleSelect = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      if (cat === "users") {
        const res = await createUserWithEmailAndPassword(
          auth,
          data.email,
          data.password
        );
        await updateProfile(auth.currentUser, {
          displayName: `${data.firstName}`,
        });
        await setDoc(doc(db, cat, res.user.uid), {
          ...data,
          timeStamp: serverTimestamp(),
        });
        navigate(-1);
        return;
      }
      await addDoc(collection(db, cat), {
        ...data,
        timeStamp: serverTimestamp(),
      });

      navigate(-1);
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>

        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://www.freeiconspng.com/uploads/no-image-icon-15.png"
              }
              width="350"
              alt="No Save Icon Format"
            />
          </div>

          <div className="right">
            <form onSubmit={handleCreate}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadTwoToneIcon className="icon" />
                </label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>

              {input.map((input) => (
                <div className="formInput" key={input.id}>
                  <label htmlFor={input.id}>{input.label}</label>
                  {/* {cat === "certificates" ? (
                    <div style={{ width: "100%" }} className="">
                      <select
                        style={{ width: "100%", height: "35px" }}
                        id={"certCatType"}
                        onChange={handleSelect}
                      >
                        <option value="">Select Certificate Category</option>

                        <option value="doc">DOC</option>
                        <option value="smc">SMC</option>
                        <option value="class">CLASS</option>
                        <option value="isps">ISPS</option>
                        <option value="n/a">Not Applicable</option>
                      </select>
                    </div>
                  ) : null} */}
                  {input.type === "vessel" && cat !== "vessels" ? (
                    <div style={{ width: "100%" }} className="">
                      <select
                        style={{ width: "100%", height: "35px" }}
                        id={"VesselId"}
                        onChange={handleSelect}
                      >
                        <option value="">{`Select ${input.type}`}</option>

                        {vesselData.map((e) => (
                          <option value={e.id}>{e.vesselName}</option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      value={data?.[input.id]}
                    />
                  )}
                </div>
              ))}
              <button type="submit">Create</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
