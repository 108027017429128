import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";

import "./table.scss";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";
import { useEffect, useState } from "react";

const TableList = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, "certificates"));
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      console.log(list);
      setData(list?.length > 0 && list);
      // setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const rows = [
  //   {
  //   	id: 102,
  //   	name: 'Certificate of Registry',
  //   	vendor: 'ABS',
  //   	officer: 'Wilson James',
  //   	sdate: new Date().toDateString(),
  //   	edate: new Date().toDateString(),
  //   	type: 'Certificate',
  //   	status: 'Due',
  //   },
  //   {
  //     id: "certType",
  //     label: "Type",
  //     type: "text",
  //     placeholder: "Annual, Intermediate, None",
  //   },
  // ];

  return (
    <Paper>
      <TableContainer component={Paper} className="table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell tableHeader">Name</TableCell>
              <TableCell className="tableCell tableHeader">Type</TableCell>
              {/* <TableCell className="tableCell">Status</TableCell> */}
              <TableCell className="tableCell tableHeader">Category</TableCell>
              <TableCell className="tableCell tableHeader">
                Issue Date
              </TableCell>
              <TableCell className="tableCell tableHeader">
                Expiry Date
              </TableCell>
              {/* <TableCell className="tableCell">Expiry</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              (row, index) =>
                (new Date(row.certEndDate).getTime() - new Date().getTime()) /
                  (1000 * 3600 * 24) <
                  0 && (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">{row?.certName}</TableCell>
                    <TableCell className="tableCell">{row?.certType}</TableCell>
                    {/* <TableCell className="tableCell">{row?.vendor}</TableCell> */}
                    <TableCell className="tableCell">{row?.certCat}</TableCell>
                    <TableCell className="tableCell">
                      {row?.certStartDate}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row?.certEndDate}
                    </TableCell>
                    {/* <TableCell className="tableCell">
                    <span className={`status ${row?.status}`}>
                      {row?.status}
                    </span>
                  </TableCell> */}
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableList;
