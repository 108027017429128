import { useState, useContext } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import usePasswordToggle from "../../hooks/usePasswordToggle";
import { auth } from "../../firebase-config";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./login.scss";

const Login = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userData = await signInWithEmailAndPassword(auth, email, password);
      dispatch({ type: "LOGIN", payload: userData });
      // console.log(auth);
      navigate("/");
    } catch (error) {
      if (error.message === "Firebase: Error (auth/network-request-failed).") {
        setError("Check your internet connection");
      } else {
        console.log(error.message);
        setError("incorrect email or password");
      }
    }
    // .then((userCredential) => {
    //   // Signed in
    //   const user = userCredential.user;
    //   // ...
    // })
    // .catch((error) => {
    //   const errorCode = error.code;
    //   const errorMessage = error.message;
    // });
  };
  return (
    <div className="login">
      <form onSubmit={handleLogin}>
        {error && <span className="error-msg">{error}</span>}
        <h2>
          Marine Vessel <span> DocTracker</span>
        </h2>
        <div className="form-group">
          <input
            maxLength={100}
            type="email"
            placeholder="Email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            maxLength={32}
            type={PasswordInputType}
            placeholder="Password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="password-toggle">{ToggleIcon}</span>
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
