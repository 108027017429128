export const userInput = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "John",
  },
  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Doe",
  },
  // {
  //   id: "vessel",
  //   label: "Vessel",
  //   type: "vessel",
  // },
  {
    id: "email",
    label: "Email",
    type: "email",
    placeholder: "john.doe@example.com",
  },
  {
    id: "password",
    label: "Password",
    type: "password",
  },
];
export const vesselInput = [
  {
    id: "vesselLocation",
    label: "Location",
    type: "text",
    placeholder: "UAE",
  },
  {
    id: "vesselName",
    label: "Name",
    type: "text",
    placeholder: "MV BIGROLL",
  },
  {
    id: "vesselClient",
    label: "Client",
    type: "text",
    placeholder: "Shell",
  },
];
export const certificateInput = [
  {
    id: "certType",
    label: "Type",
    type: "text",
    placeholder: "Annual, Intermediate, None",
  },
  {
    id: "certName",
    label: "Name",
    type: "text",
    placeholder: "Certificate of Registry",
  },
  {
    id: "certCat",
    label: "Category",
    type: "text",
    placeholder: "Class Certificate",
  },
  {
    id: "vessel",
    label: "Vessel",
    type: "vessel",
  },
  {
    id: "certStartDate",
    label: "Date of Issue",
    type: "date",
  },
  {
    id: "certEndDate",
    label: "Date of Expiry",
    type: "date",
  },
];
export const surveyInput = [
  {
    id: "surveyType",
    label: "Type",
    type: "text",
    placeholder: "Annual, Intermediate, None",
  },
  {
    id: "certName",
    label: "Name",
    type: "text",
    placeholder: "Certificate of Registry",
  },
  {
    id: "certCat",
    label: "Category",
    type: "text",
    placeholder: "Class Certificate",
  },
  {
    id: "certStartDate",
    label: "Date of Issue",
    type: "date",
  },
  {
    id: "certEndDate",
    label: "Date of Expiry",
    type: "date",
  },
];
