import { formatDistance } from "date-fns";
import moment from "moment";
const userColumns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 70,
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "firstName",
      headerName: "First name",
      width: 130,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 130,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 90,
      renderCell: (params) => (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      ),
      flex: 1,
      minWidth: 100,
    },
  ],
  certificateColumns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 70,
    //   flex: 1,
    //   minWidth: 100,
    // },
    {
      field: "certName",
      headerName: "Name",
      width: 150,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "certType",
      headerName: "Type",
      width: 70,
      flex: 1,
      minWidth: 100,
    },

    {
      field: "status",
      headerName: "Status",
      width: 70,
      renderCell: (params) => (
        <div
          className={`cellWithStatus ${
            moment(params.row.certEndDate).diff(moment(), "days") <= 30
              ? "overDue"
              : "onSchedule"
          }`}
        >
          {params.row.status}
        </div>
      ),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "certCat",
      headerName: "Category",
      width: 130,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "certStartDate",
      headerName: "Issue Date",
      width: 90,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "certEndDate",
      headerName: "Expiry Date",
      width: 130,
      flex: 1,
      minWidth: 100,
    },

    {
      field: "dueDate",
      headerName: "Expiry",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 120,

      renderCell: (params) =>
        // moment(params.row.certEndDate).diff(moment(), "days")>=30
        `${moment(params.row.certEndDate).diff(moment(), "days")} day(s)`,
      //   : `${moment(params.row.certEndDate).diff(moment(), "months")} month(s)`,
      // 	`${formatDistance(new Date(params.row.certEndDate), new Date(), {
      // 		addSuffix: true,
      // 	})}`
      flex: 1,
      minWidth: 100,
    },
  ],
  vesselColumns = [
    // { field: "id", headerName: "ID", width: 70, headerAlign: "center" },
    {
      field: "vesselName",
      headerName: "Name",
      width: 130,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "vesselLocation",
      headerName: "Location",
      width: 130,
      flex: 1,
      minWidth: 100,
    },
    {
      field: "vesselClient",
      headerName: "Client",
      width: 130,
      flex: 1,
      minWidth: 100,
    },
  ];

export const columns = {
  userColumns,
  certificateColumns,
  vesselColumns,
};

// Temporal Data

// export const userRows = [
//   {
//     id: 1,
//     firstName: 'Davis',
//     lastName: 'Mills',
//     img: null,
//     status: 'Active',
//     role: 'admin',
//     email: 'davis01@gmail.com',
//   },
// ];
