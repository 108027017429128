import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import {
  certificateInput,
  surveyInput,
  userInput,
  vesselInput,
} from "../../formSource";
import { useState } from "react";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase-config";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DriveFolderUploadTwoToneIcon from "@mui/icons-material/DriveFolderUploadTwoTone";
import "./edit.scss";
import { useNavigate } from "react-router-dom";
import { DialogActions, DialogContent } from "@mui/material";

const emails = ["username@gmail.com", "user02@gmail.com"];

export default function SimpleDialogDemo({
  cat,
  title,
  showEdit,
  dataz,
  handleRefresh,
}) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [file, setFile] = useState("");
  const [data, setData] = useState(dataz ? dataz : {});
  const [input, setInput] = useState(
    cat == "users"
      ? userInput
      : cat == "vessels"
      ? vesselInput
      : certificateInput
  );
  const navigate = useNavigate();
  // const [error, setError] = useState(false);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  //   React.useEffect(()=>{
  //   console.log(dataz);

  //   },[dataz])

  const handleSelect = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  // console.log(data);
  const handleCreate = async (e) => {
    e.preventDefault();
    console.log(data);
    try {
      const docRef = await setDoc(doc(db, cat, dataz.id), {
        ...data,
        timeStamp: serverTimestamp(),
      });
      handleClose();
      handleRefresh();

      //   navigate(-1);
    } catch (error) {
      console.log(error.message);
      handleRefresh();
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="">
      <div variant="outlined" onClick={handleClickOpen}>
        Edit
      </div>
      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle>Subscribe</DialogTitle> */}
        <DialogContent style={{ width: "550px" }}>
          <div className="edit">
            <div className="newContainer">
              {/* <Navbar /> */}
              <div className="top">
                <h1>{title}</h1>
              </div>

              <div className="bottom">
                <div className="left">
                  <img
                    // style={{ width: "30px", height: "30px" }}
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : "https://www.freeiconspng.com/uploads/no-image-icon-15.png"
                    }
                    width="350"
                    alt="No Save Icon Format"
                  />
                </div>

                <div className="right">
                  <form onSubmit={handleCreate}>
                    <div className="formInput">
                      <label htmlFor="file">
                        Image: <DriveFolderUploadTwoToneIcon className="icon" />
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        name="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>

                    {input.map((input) => (
                      <div className="formInput" key={input.id}>
                        <label htmlFor={input.id}>{input.label}</label>
                        <input
                          id={input.id}
                          type={input.type}
                          placeholder={input.placeholder}
                          onChange={handleInput}
                          value={data[input.id]}
                        />
                      </div>
                    ))}
                    {cat === "surveys" && (
                      <div className="formInput">
                        <label htmlFor="surveyType">Type:</label>
                        <select id={"surveyType"} onChange={handleSelect}>
                          <option value="doc">DOC</option>
                          <option value="smc">SMC</option>
                          <option value="class">CLASS</option>
                          <option value="isps">ISPS</option>
                        </select>
                      </div>
                    )}
                    <Button type="submit">Update Record</Button>
                  </form>
                </div>
              </div>
            </div>
            {/* <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        /> */}
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
