import "./sidebar.scss";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import DirectionsBoatTwoToneIcon from "@mui/icons-material/DirectionsBoatTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import PendingActionsTwoToneIcon from "@mui/icons-material/PendingActionsTwoTone";
import PollTwoToneIcon from "@mui/icons-material/PollTwoTone";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { auth } from "../../firebase-config";
import { useContext, useState, useEffect } from "react";
import { signOut } from "firebase/auth";

const Sidebar = () => {
  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      const userData = await signOut(auth);
      console.log(userData);
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/">
          <span className="logo">Marine Vessel</span>
          <br />
          <span className="logo logo-dim">DocTracker</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <Link to="/">
            <li>
              <GridViewTwoToneIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">USERS</p>
          <Link to="/users">
            <li>
              <PeopleAltTwoToneIcon className="icon" />
              <span>All Users</span>
            </li>
          </Link>
          <p className="title">ASSETS</p>
          <Link to="/vessels">
            <li>
              <DirectionsBoatTwoToneIcon className="icon" />
              <span>Vessels</span>
            </li>
          </Link>
          <p className="title">CONTRACT TRACKERS</p>

          <Link to="/certificates">
            <li>
              <PendingActionsTwoToneIcon className="icon" />
              <span>Certificates</span>
            </li>
          </Link>

          {/* <Link to="/surveys">
						<li>
							<PollTwoToneIcon className="icon" />
							<span>Surveys</span>
						</li>
					</Link> */}
          <p className="title">OTHERS</p>
          {/* <li>
						<SettingsTwoToneIcon className="icon" />
						<span>Settings</span>
					</li> */}
          <li>
            <LogoutTwoToneIcon className="icon" />
            <span onClick={() => handleSignOut()}>logout</span>
          </li>
        </ul>
      </div>
      <hr />
      <div className="bottom">
        <div className="colorOption"></div>
        <div className="colorOption"></div>
      </div>
      <footer>
        <p>Copyright &copy; docTracker {new Date().getFullYear()}</p>
      </footer>
    </div>
  );
};

export default Sidebar;
