import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import Datatable from "../../components/datatable/Datatable";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
import { db } from "../../firebase-config";
import Edit from "../edit/Edit";

import "./list.scss";

const List = ({ cat }) => {
  const [data, setData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vesselData, setVesselData] = useState([]);

  // console.log(data);

  // 	 const handleClickOpen = () => {
  //     //  setOpen(true);
  //    };
  const getData = async () => {
    // alert('hi')
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, cat));
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      console.log(list);
      setData(list);
      setIsLoading(false);
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, [cat]);

  const handleRefresh = () => {
    getData();
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, cat, id));
      setData(data.filter((item) => item.id !== id));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleEdit = async (id) => {
    console.log(id);
    setShowEdit(true);
  };
  console.log(showEdit);

  const handleView = (id) => {
    console.log(id);
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {isLoading && <div className="loading">Loading data....</div>}
        {data && (
          <Datatable
            data={data}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            showEdit={showEdit}
            // handleView={handleView}
            cat={cat}
            handleRefresh={handleRefresh}
          />
        )}

        {/* {showEdit && } */}
      </div>
    </div>
  );
};

export default List;
