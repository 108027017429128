import { useState } from 'react';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';

const usePasswordToggle = () => {
  const [visible, setVisible] = useState(false);
  const Icon = visible ? (
    <VisibilityTwoToneIcon
      onClick={() => setVisible((visiblity) => !visiblity)}
    />
  ) : (
    <VisibilityOffTwoToneIcon
      onClick={() => setVisible((visiblity) => !visiblity)}
    />
  );
  const InputType = visible ? 'text' : 'password';
  return [InputType, Icon];
};

export default usePasswordToggle;
