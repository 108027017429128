import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Edit from "../../pages/edit/Edit";
import { columns } from "../../datatablesource";
import "./datatable.scss";

const Datatable = ({ data, handleEdit, handleDelete, cat, handleRefresh }) => {
  const [open, setOpen] = useState(false);
  // const [data, setData] = useState(null);

  console.log(columns);
  console.log(data);

  let column;

  switch (cat) {
    case "users":
      column = columns.userColumns;
      break;
    case "vessels":
      column = columns.vesselColumns;
      break;
    case "certificates":
      column = columns.certificateColumns;
      break;
    default:
      column = "";
  }
  console.log(column);

  const action = [
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",

      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to={`./${params.row.id}`}>
              <div className="viewButton">View</div>
            </Link> */}
            <div
              className="editButton"
              onClick={() => {
                handleEdit(params.row.id);
                // handleClickOpen(true)
              }}
            >
              <Edit
                cat={cat}
                title={
                  cat === "users"
                    ? "Edit User"
                    : cat === "vessels"
                    ? "Edit Vessel"
                    : "Edit Certificate"
                }
                handleRefresh={handleRefresh}
                dataz={params.row}
              />
            </div>

            <div
              className="deleteButton"
              onClick={() => {
                // console.log(params)
                handleDelete(params.row.id);
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <Link to={"./new"}>
        <button className="addItem">
          Add <AddTwoToneIcon />
        </button>
      </Link>
      <DataGrid
        rows={data}
        columns={column.concat(action)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
      />
    </div>
  );
};

export default Datatable;
