import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Widgets from "../../components/widgets/Widgets";
import Chart from "../../components/chart/Chart";
import Table from "../../components/table/Table";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import { useContext, useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import "./home.scss";

import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase-config";

const Home = ({ type }) => {
  const [data, setData] = useState(null);

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const handleSignOut = async () => {
    try {
      const userData = await signOut(auth);
      console.log(userData);
      dispatch({ type: "LOGOUT" });
      navigate("/login");
    } catch (error) {
      console.log(error.message);
    }
  };

  const getData = async () => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, type));
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      console.log(list);
      setData(list?.length > 0 && list);
      //   setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="home">
      <Sidebar handleSignOut={handleSignOut} />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widgets type="users" />
          <Widgets type="certificates" />
          {/* <Widgets type="surveys" /> */}
          <Widgets type="vessels" />
        </div>
        <div className="charts">
          <Chart
            aspect={2 / 0.5}
            title={"Tracking utilization for the last 6 Months"}
          />
        </div>
        <div className="listContainer">
          <div className="listTitle">Due Certificate for renewal entries</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Home;
