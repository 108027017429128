import "./widget.scss";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import PendingActionsTwoToneIcon from "@mui/icons-material/PendingActionsTwoTone";
import PollTwoToneIcon from "@mui/icons-material/PollTwoTone";
import DirectionsBoatTwoToneIcon from "@mui/icons-material/DirectionsBoatTwoTone";
import { db } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Widgets = ({ type }) => {
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, type));
      querySnapshot.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      console.log(list);
      setDatas(list?.length > 0 && list);
      //   setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  let data;
  switch (type) {
    case "users":
      data = {
        title: "USERS",
        link: "see all users",
        count: datas?.length || 0,
        icon: (
          <PeopleAltTwoToneIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "certificates":
      data = {
        title: "CERTIFICATES",
        link: "see all certificates",
        count: datas?.length || 0,
        icon: (
          <PendingActionsTwoToneIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    // case 'surveys':
    //   data = {
    //     title: "SURVEYS",
    //     link: "see all surveys",
    //     count: datas?.length||0,
    //     icon: (
    //       <PollTwoToneIcon
    //         className="icon"
    //         style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
    //       />
    //     ),
    //   };
    //   break;
    case "vessels":
      data = {
        title: "VESSELS",
        link: "see all vessels",
        count: datas?.length || 0,
        icon: (
          <DirectionsBoatTwoToneIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }
  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">{data.count}</span>
        <span className="link">
          <Link to={`/${data.title.toLowerCase()}`}>{data.link}</Link>
        </span>
      </div>
      <div className="right">
        <div className="percentage positive">
          {/* <ArrowUpwardTwoToneIcon />
          20% */}
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widgets;
