import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  certificateInput,
  surveyInput,
  userInput,
  vesselInput,
} from "./formSource";
import "./style/dark.scss";
import { AuthContext } from "./context/AuthContext";

function App() {
  const { currentUser } = useContext(AuthContext);
  console.log(currentUser);
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to={"/login"} />;
  };
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <List cat={"users"} />
                  </RequireAuth>
                }
              />
              {/* <Route path=":userId" element={<Single />} /> */}
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New
                      input={userInput}
                      title={"Add New User"}
                      cat={"users"}
                    />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="vessels">
              <Route
                index
                element={
                  <RequireAuth>
                    <List cat={"vessels"} />
                  </RequireAuth>
                }
              />
              <Route
                path=":vesselId"
                element={
                  <RequireAuth>
                    <Single data={"Vessel"} />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New
                      input={vesselInput}
                      title={"Add New Vessel"}
                      cat={"vessels"}
                    />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="certificates">
              <Route
                index
                element={
                  <RequireAuth>
                    <List cat={"certificates"} />
                  </RequireAuth>
                }
              />
              <Route path=":certificateId" element={<Single />} />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New
                      input={certificateInput}
                      title={"Add New Certificate"}
                      cat={"certificates"}
                    />
                  </RequireAuth>
                }
              />
            </Route>
            <Route path="surveys">
              <Route
                index
                element={
                  <RequireAuth>
                    <List cat={"surveys"} />
                  </RequireAuth>
                }
              />
              <Route
                path=":surveyId"
                element={
                  <RequireAuth>
                    <Single />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New
                      input={surveyInput}
                      title={"Add New Survey"}
                      cat={"surveys"}
                    />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
