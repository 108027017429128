import Chart from '../../components/chart/Chart';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import List from '../../components/table/Table';
import './single.scss';

const Single = ({ data }) => {
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">{`${data} Information`}</h1>
            <div className="items">
              <img
                src="https://images.pexels.com/photos/416431/pexels-photo-416431.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt="vessel"
                className="itemImg"
              />
              <div className="details">
                <h1 className="detailTitle">MV BIGROLL</h1>
                <div className="detailItem">
                  <span className="detailItemKey">Location:</span>
                  <span className="detailItemValue">UAE:</span>
                </div>
                <div className="detailItem">
                  <span className="detailItemKey">Spec:</span>
                  <span className="detailItemValue">Anchor Handling</span>
                </div>
                <div className="detailItem">
                  <span className="detailItemKey">Client:</span>
                  <span className="detailItemValue">Shell</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart
              aspect={2 / 0.5}
              title={'MV BIGROLL Utilization (Last 6 Months)'}
            />
          </div>
        </div>
        <div className="bottom">
          <h1 className="title">{`Last ${data} Entires`}</h1>
          <List />
        </div>
      </div>
    </div>
  );
};

export default Single;
